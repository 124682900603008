@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@600&family=Raleway:wght@900&family=Inter&display=swap");

a {
  text-decoration: none;
  color: #d9832b;
}

.hero {
  background-image: url(../img/herobg.jpg);
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  background-attachment: fixed;
  overflow: hidden;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  clear: both;
}

.hero .container {
  text-align: center;
  margin-top: -60px;
}

.hero h1 {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  line-height: 0;
  font-size: 60px;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(54, 54, 54, 1);
}

.profileHeader {
  padding: 20px;
}

.column {
  display: flex;
  margin: 0;
}

.logo {
  float: left;
  padding: 16px 14px;
  z-index: 99999;
}

.logo img {
  height: auto;
  width: 120px;
}

.projectCol {
  width: 50%;
  float: left;
  padding: 10px 20px 20px 0;
  box-sizing: border-box;
}

.solidBg .projectCol {
  padding-bottom: 0;
}

.projectCol h4 {
  margin: 0 !important;
  padding: 0 !important;
  color: #d9832b;
}

.projectCol img {
  width: 100%;
  height: auto;
}

.project {
  clear: both;
}

.tools {
  padding-top: 0;
  margin-top: 0;
}

.tools span {
  padding: 5px 7px;
  margin-right: 7px;
  border-radius: 10px;
  background-color: #f5f5f5;
  color: #000;
  font-size: 11px;
  white-space: nowrap;
}

.avatarCol {
  width: 25%;
  float: left;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.bio {
  padding: 20px;
  max-width: 70%;
  float: left;
  box-sizing: border-box;
}

.bio p {
  line-height: 32px;
}

nav {
  width: 100%;
  height: 60px;
  background-color: #080808;
  position: fixed;
  top: 0;
  z-index: 99999;
}

nav .container {
  padding: 0;
}

nav ul {
  float: right;
  margin: 0;
  padding: 0;
}

nav ul li {
  float: left;
  list-style-type: none;
  position: relative;
  font-size: 1em;
}

nav ul li a {
  padding: 16px 14px;
  display: block;
}

nav ul li:hover a {
  background-color: #202736;
}

nav ul li ul {
  display: none;
  position: absolute;
  background-color: #202736;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #080808;
  border-top: none;
}

nav ul li:hover ul {
  display: block;
}

nav ul li ul li {
  width: 200px;
}

nav ul li ul li a {
  padding: 12px 14px;
  font-size: 0.8em;
}

nav ul li ul li:last-child a {
  border-radius: 0px 0px 10px 10px;
}

nav ul li ul li:hover a {
  background-color: #080808 !important;
}

nav .tinyText {
  color: #fff;
  padding: 5px 0 0 0;
  margin: 0;
  font-size: 0.7em;
}

.avatar {
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.headline {
  font-family: "Inconsolata", monospace;
  font-size: 50px;
  text-shadow: 1px 1px 1px rgba(54, 54, 54, 1);
  color: #ee0;
}

.stack {
  height: 300px;
  float: left;
  width: 20%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stack img {
  width: 40%;
}

.project {
  display: block;
}

.pageHeader {
  margin-top: 60px;
  background-color: #202736;
}

.pageHeader .container {
  padding: 80px !important;
}

.pageHeader .container h1 {
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 60px;
  letter-spacing: 7px;
}

.pageHeader .tools {
  text-align: center;
}

.pageHeader .tools span {
  font-size: 14px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 10px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.halfField {
  max-width: 49%;
}

.container.contact {
  clear: both;
}

input[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

.react-multi-carousel-list {
  padding: 0;
}

.slideImg {
  display: "block";
  height: "100%";
  margin: "auto";
  width: "100%";
}

.contactNote {
  font-size: 1rem;
  margin-bottom: 10px;
  padding: 12px 10px;
  color: #31708f;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
}

.sm-icons {
  padding: 10px;
  float: left;
}

.sm-icons a {
  margin: 0 5px;
}

.socialMediaIcon {
  width: 1.8rem;
  height: 1.8rem;
}

.heroCallout {
  background: rgba(26, 32, 44, 0.5);
}

.featured {
  background: #080808;
}

.featuredImg {
  width: 100%;
}

.featured .img {
  width: 65%;
  float: left;
}

.featured .info {
  width: 30%;
  float: left;
  padding: 10px 20px;
}

.featured .img,
.featured .info {
  box-sizing: border-box;
}

.featured h4 {
  margin: 0 0 5px 0 !important;
  padding: 0 !important;
  color: #d9832b;
}

.clear {
  clear: both;
}

.featured .tools span {
  font-size: 12px;
  font-weight: bold;
}

.listBoxHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
}

#knowledgeSection {
  background-color: #202736;
}

#knowledgeSection h3 {
  margin-bottom: 0;
  color: #d9832b;
}

#knowledgeSection p {
  margin-top: 0;
}

#knowledgeSection strong {
  color: #d9832b;
}

.listBox {
  flex: 0 1 auto;
  align-self: auto;
  min-width: 270px;
  margin-right: 10px;
  padding: 10px;
}

.listBoxLearning {
  flex: 1 1 auto;
  align-self: auto;
  margin-right: 10px;
  padding: 10px;
  background: #080808;
}

.info p {
  font-size: 0.9em;
}

.learnMore {
  margin-top: 15px;
  padding: 10px;
  background-color: #080808;
  border: 2px solid #202736;
}

.learnMore:hover {
  background-color: #fcf8e3;
}

.solidBg .project {
  margin-top: 20px;
  background-color: #080808;
}

.solidBg .img {
  padding-top: 0;
  margin: 0;
  padding-bottom: 0;
}

footer {
  margin-top: 20px;
  padding: 30px 0;
  background-color: #080808;
  font-family: Inter;
}

footer p {
  font-size: 0.8em;
  max-width: 550px;
  line-height: 1.5em;
}

.emailAddress {
  float: left;
  margin-bottom: 64px;
  padding: 12px 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 250ms ease, color 250ms ease, border 250ms ease;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #1a1a1a;
}

.emailAddress:hover {
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}

.sectionTitle {
  text-transform: uppercase;
}

.visitWebsite {
  margin-bottom: 45px;
}

.visitWebsite a {
  padding: 12px 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 250ms ease, color 250ms ease, border 250ms ease;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #1a1a1a;
}

.visitWebsite a:hover {
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}

.additionalInfo ul {
  columns: 4;
}

.additionalInfo:last-child ul {
  columns: 3;
}

.password {
  padding: 1px 5px;
  font-size: 0.8em;
  font-family: Inter;
  font-weight: bold;
  color: #000;
  background-color: #fcf8e3;
}

.password:hover {
  background-color: #fff;
}

.downloadResume {
  text-align: center;
  margin-bottom: 0;
}

.downloadResume a {
  text-align: center;
  background-color: #d9832b;
  border: none;
  color: white;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.downloadResume a img {
  height: 14px;
  margin-right: 8px;
}

.summary {
  background-color: #080808;
}

.synopses {
  padding: 10px 20px;
}

.resume h3 {
  font-family: Inter;
  font-weight: normal;
  text-transform: uppercase;
  padding: 3px 15px;
  margin: 15px 0;
  font-size: 23px;
  color: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  letter-spacing: 0.1em;
}

.resume p {
  margin: 5px 0 20px 0;
  padding: 0 15px;
  font-family: Inter;
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.1em;
}

.resume li {
  font-size: 19px;
}

.resume {
  background-color: #080808;
  margin-top: 20px;
}

.alignRight {
  float: right;
}

.additionalInfo {
  background-color: #202736;
  padding: 10px 20px;
  margin-top: 20px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media all and (max-width: 900px) {
  .additionalInfo ul {
    columns: 2;
  }
  .additionalInfo:last-of-type ul {
    columns: 1;
  }
}

@media all and (max-width: 750px) {
  .additionalInfo ul {
    columns: 1;
  }
  .container {
    flex-wrap: wrap;
    width: unset;
  }

  nav {
    display: none;
  }
  .hero h1 {
    line-height: initial;
    font-size: 45px;
  }
  .headline {
    font-size: 30px;
  }
  .avatarCol,
  .projectCol,
  .bio {
    width: 100%;
    padding: 0;
    max-width: unset;
  }
  .avatar {
    width: 80%;
  }

  .bio h3 {
    text-align: center;
  }

  .bio p {
    font-size: 20px;
    text-align: justify;
  }
  .halfField {
    max-width: 100%;
  }
  .info {
    order: 1;
    margin-top: 10px;
  }
  .img {
    order: 2;
    margin-bottom: 10px;
  }
  .projectCol p:not(.tools) {
    display: none;
  }
  .project {
    display: flex;
    flex-direction: column;
  }
  .react-multi-carousel-list {
    width: 100%;
  }
  .sm-icons {
    float: none;
    display: block;
  }
  .featured .img,
  .featured .info {
    width: 100%;
    float: unset;
  }
  .viewMorePorjects {
    display: block;
  }
  .pageHeader {
    margin-top: 0;
  }
}
